import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Hero from "../components/hero"
import TestimonialSection from "../components/testimonials-section"
import Cta from "../components/cta"
import ArrowRight from "../components/svg/arrowRight"
import Seo from "../components/seo"

const CharityPage = () => (
    <Layout>
        <Seo 
            title="Charity web design and development" 
            description="Launch Lab are local experienced charity and NGO website designers and web developers in Sydney and Canberra, Australia."
            pathname="/charity-websites/"
            serviceType="Charity website design and development"
        />

        <Hero
            h1="Charity / NGO Web Design and Development"  
            h2="Experienced Charity / NGO website designers and web developers in Sydney and Canberra"
        />

        <section className="bg-purple with-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="below-hero"
                        data-sal="slide-right" 
                        data-sal-easing="ease-in-out"
                        data-sal-duration="400"
                    >
                        <div className="below-hero-widget">
                            <span className="widget-heading">Websites</span>
                            <p>We design and develop fast loading, easy-to-use, websites using either Gatsby or Webflow.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">CMS</span>
                            <p>We develop user friendly CMS' using either a headless CMS (like Contentful) or Webflow.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">Software</span>
                            <p>It's not just websites. We have the development skills in-house to extend your website and develop applications.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3
                            >Australian web developers</h3>
                        </div>

                        <div className="col-67 long-text"
                            data-sal="slide-up" 
                            data-sal-easing="ease-in-out"
                            data-sal-duration="400"
                        >
                            <p>
                                Over the years Launch Lab has developed websites, apps, online competitions, content management systems and written digital strategies for charities, NGOs and non-profit organisations.
                            </p>
                            <p>
                                We understand the importance of community, content creation, social media and donations and how this ties into creating nonprofit websites that aren't just a front door to your charity but an engagement gateway for your organisation.
                            </p>
                            <p>
                                Our NGO services include:
                            </p>
                            <ul>
                                <li>Responsive <Link to="/web-design/">web design</Link></li>
                                <li><Link to="/web-development/">Web development</Link></li>
                                <li>Easy to use content management systems (CMS)</li>
                                <li>Donation pages &amp; payment gateway integration</li>
                                <li>Integration with email marketing software</li>
                                <li>Scalable and secure hosting</li>
                            </ul>
                            <div className="btn-row">
                                <Link to="/portfolio/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                        Our work
                                    <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="container">
            <div className="row">
            <div className="section-line"></div>
            </div>
        </div>

        <section className="section-pad">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h4>Experienced local development team</h4>
                        </div>

                        <div className="col-67 long-text">
                            <p>
                                Launch Lab is a <Link to="/about/">web development company in Sydney and Canberra</Link>.
                            </p>
                            <p>
                                We're trusted by startups, Government, large corporates and charitable organisations to design and develop websites and software that is scalable and easy to use.
                            </p>
                            <p>
                                We have experience developing websites, custom CMSs, software, integrating payment gateways and have used 3rd party tools trusted by charities around the world like BSD tools, Blackbaud (NetCommunity), Care2 as well as other leading CRM solutions.
                            </p>
                            <p>
                                If you are a charity / NGO / non-profit organisation in Australia and are about to embark on a new website design or web development project contact us for an obligation free quote and discussion outlining how Launch Lab can add value to your next website.
                            </p>
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                        Contact us
                                    <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <TestimonialSection />

        <Cta 
            text="Ready to discuss your next charity / NGO / not-for-profit web design or web development project? Get an obligation free web design quote."
        />
    </Layout>
)

export default CharityPage